.page-product-catalog .title-bar-breadcrumbs-btn
{
	display: inline-flex;
	align-items: center;
	margin-bottom: 24px;
}
.page-product-catalog .title-bar-breadcrumbs-btn .title-bar-breadcrumbs
{
	margin-right: 40px;
}
.page-product-catalog .title-bar-breadcrumbs-btn .breadcrumbs
{
	margin-bottom: 0;
}

.page-product-catalog .category-box
{
	margin-bottom: 40px;
}
.page-product-catalog .category-box .category-title-bar
{
	background-color: #f0f6ff;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
	margin-bottom: 8px;
	box-sizing: border-box;
}
.page-product-catalog .category-box .category-title-bar .title
{
	color: #000000;
	font-size: 14px;
}
.page-product-catalog .category-box .category-title-bar .btn-edit
{
	background-color: transparent !important;
	height: unset;
	color: #3e7fe1 !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	padding: 0 !important;
	margin-left: 24px;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.page-product-catalog .category-box .category-title-bar .btn-close-open
{
	color: #191b1c;
}
.page-product-catalog .category-box .products-box
{
	/*display: inline-flex;*/
	/*align-items: center;*/
}
.page-product-catalog .category-box .products-box .product-box
{
	background-color: #ffffff;
	min-width: 100px;
	display: inline-flex;
	align-items: center;
	flex-direction: column;
	padding: 8px;
	margin: 8px;
	border: 2px solid #191b1c;
	border-radius: 8px;
	cursor: pointer;
}
.page-product-catalog .category-box .products-box .product-box .title
{
	color: #191b1c;
	margin-bottom: 12px;
}
.page-product-catalog .category-box .products-box .product-box .initial
{
	background-color: #e5e7e8;
	color: #000000;
	padding: 8px 12px;
	border-radius: 100px;
}





.form-product-catalog-box
{

}
.form-product-catalog-box .section-box
{
	margin-bottom: 24px;
}
.form-product-catalog-box .section-box .section-title-bar
{
	background-color: #f0f6ff;
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
	/*margin-bottom: 24px;*/
	box-sizing: border-box;
}
.form-product-catalog-box .section-box .section-title-bar.done
{
	background-color: #e7f7ef;
}
.form-product-catalog-box .section-box .section-title-bar .title
{
	width: 180px;
	color: #000000;
	font-size: 14px;
	display: inline-block;
}
.form-product-catalog-box .section-box .section-title-bar .btn-edit
{
	background-color: transparent !important;
	height: unset;
	color: #3e7fe1 !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	padding: 0 !important;
	margin-left: 24px;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.form-product-catalog-box .section-box .section-title-bar .btn-close-open
{
	color: #191b1c;
}
.form-product-catalog-box .section-box .section-title-bar .icon-check
{
	color: #0faf62;
}

.form-product-catalog-box .section-box .section-content
{
	margin-top: 24px;
}

.form-product-catalog-box .alert-gray
{
	background-color: #f5f6f7;
	color: #191b1c;
	margin-bottom: 24px;
}
.form-product-catalog-box .alert-gray .MuiAlert-icon
{
	color: #191b1c;
}




.form-product-catalog-box .form-label-t2
{
	color: #959fa3;
	text-transform: uppercase;
}

.form-product-catalog-box .stations-box
{
	width: 100%;
	/*display: inline-flex;*/
	/*flex-direction: column;*/
	/*align-items: flex-start;*/
	/*justify-content: flex-start;*/
	padding: 8px;
	margin-bottom: 24px;
	border: 1px solid #e5e7e8;
	border-radius: 8px;
	box-sizing: border-box;
}
.form-product-catalog-box .stations-box .station-item
{
	background-color: #f0f6ff;
	color: #005ce8;
	font-weight: 500;
	font-size: 14px;
	display: inline-block;
	padding: 5px 16px;
	margin: 5px;
	border-radius: 130px;
	cursor: pointer;
}
.form-product-catalog-box .stations-box .station-item:hover
{
	background-color: #005ce8;
	color: #f0f6ff;
}







.form-product-catalog-box .stations-selected-box
{
	margin: 0 -13px 24px -13px;
}
.form-product-catalog-box .stations-selected-box .station-item
{
	background-color: #ffffff;
	min-width: 100px;
	display: inline-flex;
	align-items: center;
	flex-direction: column;
	padding: 8px;
	margin: 16px;
	border: 2px solid #191b1c;
	border-radius: 8px;
	position: relative;
}
.form-product-catalog-box .stations-selected-box .station-item .title
{
	color: #191b1c;
	margin-bottom: 12px;
}
.form-product-catalog-box .stations-selected-box .station-item .abbreviation
{
	background-color: #e5e7e8;
	color: #000000;
	padding: 8px 12px;
	border-radius: 100px;
}
.form-product-catalog-box .stations-selected-box .station-item .btn-remove
{
	background-color: #f0f6ff;
	color: #005ce8;
	position: absolute;
	top: -20px;
	right: -20px;
}

















.form-product-catalog-box .custom-characteristic-form-box
{
	background-color: #f5f6f7;
	display: inline-block;
	padding: 16px;
	margin-bottom: 16px;
	border-radius: 8px;
}

.form-product-catalog-box .form-button-group button
{
	background-color: transparent !important;
}
.form-product-catalog-box .form-button-group button.Mui-selected
{
	background-color: #ffffff !important;
}

.form-product-catalog-box .form-product-catalog-custom-characteristic-btn-add-to-list
{
	color: #0faf62 !important;
	position: absolute !important;
	top: 28px;
	right: 0;
}
.form-product-catalog-box .form-product-catalog-custom-characteristic-list-name-box
{
	position: relative;
}
.form-product-catalog-box .characteristics-list-box
{
	display: inline-block;
	padding-top: 16px;
	margin-bottom: 16px;
	border-top: 1px solid #3e7fe1;
}
.form-product-catalog-box .characteristics-list-box .characteristic-list-row
{
	margin-bottom: 8px;
}
.form-product-catalog-box .characteristics-list-box .name
{
	width: 200px;
	display: inline-block;
}
.form-product-catalog-box .characteristics-list-box .type
{
	width: 80px;
	display: inline-block;
}





.form-product-catalog-box .review-item
{
	color: #191b1c;
	font-size: 16px;
	margin-bottom: 8px;
}
.form-product-catalog-box .review-item:last-child
{
	margin-bottom: 0;
}



