.dialog-content-payment-default .scrollbar
{
	margin: 24px 0;
}

.dialog-content-payment-default .table-data thead th
{
	padding-top: 8px;
	padding-bottom: 8px;
}
.dialog-content-payment-default .table-data tbody td
{
	padding-top: 0;
	padding-bottom: 0;
}

.dialog-content-payment-default .table-data tbody td .MuiCheckbox-root
{
	padding-top: 0;
	padding-bottom: 0;
}

.dialog-content-payment-default .table-data tbody td .icon-edit
{
	color: #005ce8;
}
.dialog-content-payment-default .table-data tbody td .icon-delete
{
	color: #e84646;
}

.dialog-content-payment-default .scrollbar-payment-term,
.dialog-content-payment-default .scrollbar-payment-method
{
	height: 180px !important;
}

.dialog-content-payment-default .MuiAlert-root
{
	margin: 24px 0;
}

.dialog-content-payment-default .section-title
{
	font-weight: 600;
	font-size: 16px;
	color: #191b1c;
	margin-right: 24px;
}
