.page-login
{
	width: 100%;
	height: 100%;
}
.page-login .form-box
{
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.page-login .form-box .logo-box img
{
	width: 120px;
	margin-bottom: 30px;
}
.page-login .form-login
{
	background-color: #ffffff;
	border-radius: 10px;
	overflow: hidden;
}
.page-login .form-login .bg-box img
{
	width: 100%;
	height: 100%;
}
.page-login .form-login .link-to-forgot-password, .page-login .form-login .link-to-forgot-password:visited
{
	color: #005ce8;
	font-size: 14px;
	float: right;
}
.page-login .form-login .link-to-forgot-password:hover
{
	color: #2386ff;
}
.page-login .form-content
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
}
.page-login .login-des
{
	color: #626c70;
	font-size: 14px;
	margin-top: 30px;
}
.page-login .login-des .link-to-register, .page-login .login-des .link-to-register:visited
{
	color: #191b1c;
	font-size: 14px;
	font-weight: bold;
}
.page-login .login-des .link-to-register:hover
{
	color: #6a7377;
}

/* *** */

@media(max-width: 767px)
{
	.page-login .form-content
	{
		padding: 20px;
	}
}
@media(max-width: 600px)
{
	.page-login .form-login .bg-box
	{
		display: none;
	}
}
