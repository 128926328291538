.label-box
{
	background-color: #ffffff;
	display: inline-flex;
	align-items: center;
	padding: 8px;
	margin-left: 32px;
	border-radius: 4px;
}
.label-box .label-title
{
	font-weight: 600;
	font-size: 12px;
	display: inline-flex;
	align-items: center;
	margin-right: 16px;
}
.label-box .label-title:last-child
{
	margin-right: 0;
}
.label-box .label-title label
{
	font-weight: 600;
	font-size: 12px;
	margin-right: 4px;
}
