.page-quote .quote-section-container
{
	max-width: unset;
	padding: 0 !important;
}



.page-quote .title-bar-breadcrumbs-status
{
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 24px;
}
.page-quote .title-bar-breadcrumbs-status .label-status
{
	margin-left: 64px;
}
.page-quote .title-bar-breadcrumbs-status .breadcrumbs
{
	margin-bottom: 0;
}


.page-quote .section-divider
{
	margin-bottom: 8px;
}
.page-quote .section-divider .section-title
{
	width: 100%;
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	margin-right: 24px;
	text-transform: uppercase;
}




.page-quote .quote-section-box
{
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	padding: 20px;
	border-radius: 8px;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
}
.page-quote .quote-section-box .title
{
	color: #626c70;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
}



.page-quote .quote-section-action-box
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}
.page-quote .quote-section-action-box .btn-red
{
	margin-left: 8px;
}




.page-quote .quote-section-order-detail
{
	margin-bottom: 24px;
}

.page-quote .quote-section-order-detail .quote-section-box
{

}
.page-quote .quote-section-order-detail .quote-section-box .MuiGrid-container
{
	margin-top: 0;
}
.page-quote .quote-section-order-detail .quote-section-box .MuiGrid-item
{
	padding-top: 0;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box
{
	margin-bottom: 24px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box.data-box2
{
	margin-bottom: 16px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .title
{
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 8px;
	text-transform: uppercase;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .title.title2
{
	font-size: 12px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .title-by-icon
{
	display: inline-flex;
	align-items: center;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .title .icon
{
	color: #005ce8;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .des
{
	color: #191b1c;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 8px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .des.des2
{
	font-weight: 500;
	font-size: 16px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .des.des3
{
	font-weight: 500;
	font-size: 12px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .des:last-child
{
	margin-bottom: 0;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .date-blue
{
	background-color: #f0f6ff;
	color: #005ce8;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .date-green
{
	background-color: #e7f7ef;
	color: #0faf62;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}
.page-quote .quote-section-order-detail .quote-section-box .data-box .date-orange
{
	background-color: #FFF4E6;
	color: #FF9500;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}

.page-quote .quote-section-order-detail .quote-section-box .data-box .form-control-label-check-box
{
	font-size: 14px;
}

.page-quote .quote-section-order-detail .quote-section-box .table-data
{
	border: 1px solid #e5e7e8;
	border-radius: 8px;
	overflow: hidden;
}
.page-quote .quote-section-order-detail .quote-section-box .table-data th
{
	padding-top: 8px;
	padding-bottom: 8px;
}
.page-quote .quote-section-order-detail .quote-section-box .table-data td
{
	padding-top: 8px;
	padding-bottom: 8px;
}






.page-quote .quote-section-order-detail .btn-link-bar
{
	display: inline-flex;
	justify-content: space-around;
}
.page-quote .quote-section-order-detail .btn-link-bar.btn-link-bar2
{
	justify-content: space-between;
}





.page-quote .quote-section-order-jobs-box
{
	height: unset;
	padding: 0;
	overflow: hidden;
	margin-bottom: 24px;
}

.page-quote .quote-section-order-jobs-box .table-data
{
}
.page-quote .quote-section-order-jobs-box .table-data tr:hover td
{
	background-color: transparent;
}
.page-quote .quote-section-order-jobs-box .table-data .td-expand
{
	text-align: right;
	padding: 0 8px 8px 8px;
}
.page-quote .quote-section-order-jobs-box .table-data
{
}
.page-quote .quote-section-order-jobs-box .table-data .tr-display-none
{
	display: none;
}


.page-quote .quote-section-job-times-box
{
	padding: 0;
	overflow: hidden;
}

.page-order .quote-section-price-box
{
	justify-content: flex-start;
}
.page-quote .quote-section-price-box .data-box
{
	color: #4a5154;
	font-size: 14px;
	display: inline-flex;
	justify-content: space-between;
	margin-bottom: 24px;
}
.page-quote .quote-section-price-box .data-box:last-child
{
	margin-bottom: 0;
}
.page-quote .quote-section-price-box .line-bar
{
	background-color: #cacfd1;
	height: 1px;
	margin-bottom: 24px;
}




.page-quote .table-data .td-order-job
{
	padding: 0;
}
.page-quote .table-data .td-order-job .order-job-box
{
	padding: 24px;
	border: 1px solid #959fa3;
	border-radius: 8px;
}








.page-quote .table-data .td-order-job .order-job-box .MuiGrid-container
{
	margin-top: 0;
}
.page-quote .table-data .td-order-job .order-job-box .MuiGrid-item
{
	padding-top: 0;
}
.page-quote .table-data .td-order-job .order-job-box .container-date
{
	width: 100%;
	max-width: 400px;
}
.page-quote .table-data .td-order-job .order-job-box .data-box
{
	margin-bottom: 24px;
}
.page-quote .table-data .td-order-job .order-job-box .data-box .title
{
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 8px;
	text-transform: uppercase;
}
.page-quote .table-data .td-order-job .order-job-box .data-box .des
{
	color: #191b1c;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 8px;
}
.page-quote .table-data .td-order-job .order-job-box .data-box .des:last-child
{
	margin-bottom: 0;
}
.page-quote .table-data .td-order-job .order-job-box .date-blue
{
	background-color: #f0f6ff;
	color: #005ce8;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}
.page-quote .table-data .td-order-job .order-job-box .date-green
{
	background-color: #e7f7ef;
	color: #0faf62;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}
.page-quote .table-data .td-order-job .order-job-box .date-orange
{
	background-color: #FFF4E6;
	color: #FF9500;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}



.page-quote .table-data .td-order-job .order-job-box .data-box-border
{
	height: 100%;
	padding: 20px;
	margin-bottom: 0;
	border: 1px solid #cacfd1;
	border-radius: 8px;
	box-sizing: border-box;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border .title
{
	color: #959fa3;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 4px;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border .des
{
	color: #000000;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 16px;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border.note-data-box
{
	width: 100%;
	display: inline-flex;
	flex-direction: column;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border .note-box
{
	flex-basis: 100%;
	font-size: 14px;
	padding: 10px 16px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
}


.page-quote .table-data .td-order-job .order-job-box .data-box-border.production-note-data-box
{
	width: 100%;
	display: inline-flex;
	flex-direction: column;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border.production-note-data-box .form-input
{
	flex-basis: 100%;
	margin-bottom: 16px;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border.production-note-data-box .form-input .MuiInputBase-multiline
{
	height: 100% !important;
	padding: 0;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border.production-note-data-box .form-input textarea
{
	width: 100% !important;
	height: 100% !important;
	color: #959fa3;
	font-size: 14px;
	padding: 10px 16px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
	box-sizing: border-box;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border.production-note-data-box .form-input textarea:focus
{
	border: 1px solid #005ce8 !important;
}
.page-quote .table-data .td-order-job .order-job-box .data-box-border.production-note-data-box .form-input fieldset
{
	border: 0;
}


.bar-icon-show-hide
{
	position: relative;
}
.bar-icon-show-hide .btn-show-hide
{
	position: absolute;
	bottom: 0;
	right: 0;
}
