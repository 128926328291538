.page-invoice-template .form-box
{
	width: 65%;
	position: relative;
}

.form-invoice-template-btn-bar
{
	background-color: #f5f6f7;
	width: 100%;
	padding: 0 0 2px 32px;
	position: sticky;
	bottom: 0;
	left: 0;
}

@media(max-width: 768px)
{
	.page-invoice-template .form-box
	{
		width: 100%;
	}
}
