.pagination-me-box
{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pagination-me-data
{
	color: #000000;
	font-weight: 400;
	font-size: 14px;
	float: left;
}

.pagination-me
{
	float: right;
	padding: 0;
	margin: 0;
}
.pagination-me li
{
	float: left;
	list-style: none;
}
.pagination-me li span
{
	background-color: transparent;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #626c70;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	border-radius: 100%;
	text-decoration: none;
	cursor: pointer;
	transition: 0.3s all ease;
}
.pagination-me li span:focus, .pagination-me li span:hover, .pagination-me li.active span
{
	background-color: #005ce8;
	color: #ffffff;
}

.pagination-me li.space span
{
	background-color: transparent !important;
	color: #626c70 !important;
	cursor: default !important;
}

.pagination-me li.icon span
{
	background-color: #f0f6ff;
	color: #005ce8;
}
.pagination-me li.icon span svg
{
	font-size: 20px;
}
.pagination-me li.icon span:focus, .pagination-me li.icon span:hover
{
	background-color: #005ce8;
	color: #ffffff;
}
