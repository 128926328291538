.page-register
{
	width: 100%;
	height: 100%;
}
.page-register .form-box
{
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.page-register .form-box .logo-box img
{
	width: 120px;
	margin-bottom: 30px;
}
.page-register .form-register
{
	background-color: #ffffff;
	border-radius: 10px;
	overflow: hidden;
}
.page-register .form-register .bg-box img
{
	width: 100%;
	height: 100%;
}
.page-register .form-register .link-to-forgot-password, .page-register .form-register .link-to-forgot-password:visited
{
	color: #005ce8;
	font-size: 14px;
	float: right;
}
.page-register .form-register .link-to-forgot-password:hover
{
	color: #2386ff;
}
.page-register .form-content
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
}
.page-register .register-des
{
	color: #626c70;
	font-size: 14px;
	margin-top: 30px;
}
.page-register .register-des .link-to-login, .page-register .register-des .link-to-login:visited
{
	color: #191b1c;
	font-size: 14px;
	font-weight: bold;
}
.page-register .register-des .link-to-login:hover
{
	color: #6a7377;
}

/* *** */

@media(max-width: 767px)
{
	.page-register .form-content
	{
		padding: 20px;
	}
}
@media(max-width: 600px)
{
	.page-register
	{
		padding: 30px 0;
		overflow-y: scroll;
		box-sizing: border-box;
	}
	.page-register .form-box
	{
		position: relative;
		top: auto;
		left: auto;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		-webkit-transform: none;
		transform: none;
	}

	.page-register .form-register .bg-box
	{
		display: none;
	}
}
