
.form-quote-box .form-control-label-check-box .MuiCheckbox-root
{
	color: #e5e7e8;
	padding-top: 0;
	padding-bottom: 0;
}
.form-quote-box .form-control-label-check-box .Mui-checked
{
	color: #1976d2!important;
}

.form-quote-box .form-title-bar
{
	width: 100%;
	color: #1976d2;
	font-weight: 500;
	font-size: 16px;
	padding: 12px 0;
	margin-bottom: 24px;
	border-bottom: 1px solid #e5e7e8;
}

.form-quote-box .form-quote-customer-new .btn-blue
{
	margin-left: 24px;
}
