@font-face
{
	font-family: "PublicSans";
	src: local("PublicSans"), url("./fonts/PublicSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

/**********************************************************************************************************************/

body
{
	background-color: #f5f6f7;
	min-width: 320px;
	color: #191b1c;
	font-family: "PublicSans", sans-serif !important;
	font-size: 14px;
	line-height: 1.5em;
	padding: 0;
	margin: 0;
	cursor: default;
	direction: ltr;
	overflow: hidden;
}
body, html, #root, .main-body
{
	background-color: #F5F6F7;
	width: 100%;
	min-width: 320px;
	height: 100%;
	position: relative;
}

.scrollbar
{
	direction: ltr;
}

*:focus
{
	box-shadow: none !important;
}

a, a:visited
{
	text-decoration: none !important;
}
/*
.list-alert-no-data
{
	width: 100%;
	text-align: center;
	margin: 0 !important;
	position: absolute !important;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
.list-alert-no-data.alert-type-2
{
	margin-top: 30px !important;
	position: relative !important;
	top: auto;
	left: auto;
	transform: translateY(0);
}

.list-loader-wait {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
*/
.display-none
{
	display: none !important;
}

.m-l-16
{
	margin-left: 16px !important;
}
.m-t--16
{
	margin-top: -16px !important;
}
.m-b-16
{
	margin-bottom: 16px !important;
}

.p-b-20
{
	padding-bottom: 20px !important;
}

/* width */
/*
.width-30 {
	width: 30px;
}
.width-40 {
	width: 40px;
}
.width-60 {
	width: 60px;
}
.width-70 {
	width: 70px;
}
.width-80 {
	width: 80px;
}
.width-100 {
	width: 100px;
}
.width-120 {
	width: 120px;
}
.width-140 {
	width: 140px;
}
.width-150 {
	width: 150px;
}
.width-160 {
	width: 160px;
}
.width-180 {
	width: 180px;
}
.width-200 {
	width: 200px;
}
*/
/**/
/*
.text-right
{
	text-align: right;
}
*/
/* modal */
/*
body .modal-content {
	border-radius: 16px;
}
body .modal-header {
	padding: 30px;
	border: 0;
}
body .modal-body {
	padding: 10px 30px 30px 30px;
}
body .modal-title {
	font-size: 22px;
	font-weight: bold;
}
body .modal-title .icon {
	font-size: 24px;
	margin-top: -5px;
}
body .btn-close {
	background: transparent
	url("data:image/svg+xml,%3csvg xmlns='http:/www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(183, 194, 206)'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
	center/1em auto no-repeat;
	opacity: 1;
}
body .btn-close:hover {
	opacity: 0.5;
	transition: 0.3s all ease;
}
body .modal-footer .btn {
	margin-left: 10px;
}
body .popup-delete-des {
	font-size: 20px;
}
*/
/* form */
/*
body .form-label {
	color: #8f9caa;
}
body .form-control {
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #d3dae2;
	border-radius: 10px;
}
body .form-select {
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #d3dae2;
	border-radius: 10px;
}
body .form-select.no-value {
	color: #b7c2ce;
}
body .filter-input {
	width: auto;
	min-width: 120px;
	height: 40px;
	line-height: 40px;
	float: left;
	padding: 0 10px;
	margin-bottom: 0;
	border: 1px solid #d3dae2;
	border-radius: 10px;
}
body .filter-select {
	width: auto;
	min-width: 120px;
	height: 40px;
	float: left;
	padding: 0 10px;
	margin-bottom: 0;
	border: 1px solid #d3dae2;
	border-radius: 10px;
}
body .filter-search-box {
	float: left;
	position: relative;
}
body .filter-search-box .icon {
	color: #66738f;
	font-size: 20px;
	position: absolute;
	top: 11px;
	left: 10px;
}
body .filter-search-box .filter-input {
	padding: 0 10px 0 40px;
}
body .filter-search-box.filter-search-box-2 .filter-input {
	margin-left: 20px;
	padding: 0 10px 0 10px;
}
body .btn {
	border-radius: 8px;
}
body form .btn {
	height: 40px;
	margin-top: 20px;
}
body .btn.btn-width-full {
	width: 100%;
}

.form-loader-wait {
	width: 100%;
	height: 38px;
	line-height: 38px;
	text-align: center;
	margin-top: 30px;
}
.form-loader-wait.right {
	text-align: right;
}
*/
/*
.toast-container {
	margin-top: -80px;
	margin-right: -20px;
	z-index: 100000000;
}
.page-login .toast-container {
	margin-top: 0;
	margin-right: 0;
}
.toast.bg-success,
.toast.bg-danger {
	color: #ffffff;
}
*/
/**********************************************************************************************************************/

/* col-menu */

.col-menu
{
	background-color: #ffffff;
	width: 230px;
	height: 100%;
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
}

.col-menu .site-logo
{
	padding: 24px;
}
.col-menu .site-logo img
{
	width: 100px;
}

.col-menu .menu-user
{
	margin-bottom: 24px !important;
}
.col-menu .menu-user li
{
	margin-bottom: 0 !important;
}
.col-menu .menu-user .user-image
{
	width: 32px;
	height: 32px;
	object-fit: cover;
	-o-object-fit: cover;
	border-radius: 100%;
}
.col-menu .menu-user .user-name
{
	color: #000000;
	font-size: 14px;
	padding-left: 8px;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
}

.col-menu .menu-scrollbar
{
	height: calc(100% - 150px) !important;
	float: left;
	margin: 0;
	direction: ltr;
}

.col-menu .menu
{
	width: 100%;
	float: left;
	padding: 0;
	margin: 0;
	direction: ltr;
}
.col-menu .menu li
{
	width: 100%;
	float: left;
	margin-bottom: 4px;
	list-style: none;
}
.col-menu .menu li.menu-section
{
	width: 100%;
	color: #959fa3;
	font-size: 12px;
	float: left;
	padding-left: 24px;
	margin-bottom: 8px;
	list-style: none;
	box-sizing: border-box;
}
.col-menu .menu li a, .col-menu .menu li a:visited
{
	width: 100%;
	color: #626c70;
	font-size: 14px;
	text-align: left;
	padding: 6px 21px;
	border-left: 3px solid transparent;
	transition: 0.3s all ease;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}
.col-menu .menu li a:hover, .col-menu .menu li.active a
{
	background-color: #f0f6ff;
	color: #191b1c;
	border-left: 3px solid #005ce8;
}
.col-menu .menu li a .icon
{
	width: 20px;
	height: 20px;
	object-fit: contain;
	-o-object-fit: contain;
	/*color: #8d95ac;*/
	/*font-size: 20px;*/
}
.col-menu .menu li a .title
{
	line-height: 2em;
	padding-left: 8px;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
}

/* col content */

.col-content
{
	width: calc(100% - 230px);
	height: 100%;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
}

/* *** */

.col-content .search-bar
{
	background-color: #ffffff;
	width: 100%;
	padding: 16px 0 16px 32px;
	box-shadow: inset 1px 0 0 #f2f3f4;
}
.col-content .search-bar .search-box
{
	background-color: #f4f5f5;
	width: 100%;
	max-width: 240px;
	display: flex;
	align-items: center;
	border-radius: 4px;
}
.col-content .search-bar .search-box .icon
{
	color: #191b1c;
	margin-left: 16px;
	cursor: pointer;
}
.col-content .search-bar .search-box .form-input
{
	margin: 0;
}
.col-content .search-bar .search-box .form-input input
{
	background-color: transparent;
	height: 40px;
	color: #959fa3;
	border: 0;
	outline: none;
}
.col-content .search-bar .search-box .form-input input:focus
{
	border: 0 !important;
}

/* *** */

.col-content .page-content
{
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 16px 32px;
}

/* *** */

.col-content .page-content .title-bar
{
	width: 100%;
	margin-bottom: 24px;
	display: inline-flex;
	align-items: center;
	box-sizing: border-box;
}
.col-content .page-content .title-bar .title
{
	color: #191b1c;
	font-size: 16px;
	font-weight: bold;
	float: left;
}

.col-content .page-content .breadcrumbs
{
	width: 100%;
	color: #626c70;
	margin: -16px 0 24px 0;
}
.col-content .page-content .breadcrumbs .link, .col-content .page-content .breadcrumbs .link:visited
{
	color: #626c70;
	font-size: 14px;
}
.col-content .page-content .breadcrumbs .link:hover
{
	color: #859398;
}
.col-content .page-content .breadcrumbs .current-page
{
	color: #191b1c;
	font-size: 14px;
}

.col-content .page-content .title-bar .title-bar-btn,
.btn-blue
{
	background-color: #005ce8 !important;
	height: 40px;
	color: #ffffff !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.col-content .page-content .title-bar .title-bar-btn.t2,
.btn-blue.btn-blue-outline
{
	background-color: #ffffff !important;
	color: #005ce8 !important;
	box-shadow: unset;
	border: 1px solid #005ce8;
}
.btn-blue.btn-blue-outline:hover
{
	background-color: #ffffff;
	box-shadow: unset;
}


.btn-blue.btn-blue-link
{
	background-color: transparent !important;
	color: #005ce8 !important;
	border: 0;
	padding: 0 !important;
}


.btn-black
{
	background-color: #191b1c !important;
	height: 40px;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.btn-black.btn-black-outline
{
	background-color: #ffffff !important;
	color: #191b1c;
	box-shadow: unset;
	border: 1px solid #191b1c;
}
.btn-black.btn-black-outline:hover
{
	background-color: #ffffff;
	box-shadow: unset;
}

.btn-green
{
	background-color: #E7F7EF !important;
	height: 40px;
	color: #0faf62 !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.btn-green-2
{
	background-color: #0faf62 !important;
	height: 40px;
	color: #ffffff !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.btn-green:hover
{
	box-shadow: unset;
}
.btn-green.btn-green-outline
{
	background-color: #ffffff !important;
	color: #0faf62;
	box-shadow: unset;
	border: 1px solid #0faf62;
}
.btn-green.btn-green-outline:hover
{
	background-color: #ffffff;
	box-shadow: unset;
}

.btn-red
{
	background-color: #fdeded !important;
	height: 40px;
	color: #e84646 !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.btn-red:hover
{
	box-shadow: unset;
}
.btn-red.btn-red-outline
{
	background-color: #ffffff !important;
	color: #e84646;
	box-shadow: unset;
	border: 1px solid #e84646;
}
.btn-red.btn-red-outline:hover
{
	background-color: #ffffff;
	box-shadow: unset;
}



.btn-yellow
{
	background-color: #fff266 !important;
	height: 40px;
	color: #191b1c !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.btn-yellow:hover
{
	box-shadow: unset;
}
.btn-yellow.btn-yellow-outline
{
	background-color: #ffffff !important;
	color: #191b1c;
	box-shadow: unset;
	border: 1px solid #fff266;
}
.btn-yellow.btn-yellow-outline:hover
{
	background-color: #ffffff;
	box-shadow: unset;
}







.btn-group-green-2
{
	background-color: #0faf62 !important;
	height: 40px;
	color: #ffffff !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}
.btn-group-blue
{
	background-color: #005ce8 !important;
	height: 40px;
	color: #ffffff !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	margin-left: 16px;
	border-radius: 160px !important;
	text-transform: unset !important;
	box-shadow: unset !important;
}















/* *** */

.col-content .page-content .filter-bar
{
	margin-bottom: 24px;
}

.col-content .page-content .filter-bar .filter-select-box
{

}
.col-content .page-content .filter-bar .filter-select-box .MuiSelect-select
{
	background-color: #ffffff;
	width: 100px;
}
.col-content .page-content .filter-bar .filter-select-box fieldset
{
	border: 0;
}




.col-content .page-content .search-box
{
	background-color: #ffffff;
	width: 100%;
	max-width: 240px;
	display: flex;
	align-items: center;
	border-radius: 4px;
}
.col-content .page-content .search-box .icon
{
	color: #191b1c;
	margin-left: 16px;
	cursor: pointer;
}
.col-content .page-content .search-box .form-input
{
	margin: 0;
}
.col-content .page-content .search-box .form-input input
{
	height: 40px;
	color: #959fa3;
	border: 0;
	outline: none;
}

/*
.col-content .title-bar
{
	width: 100%;
	height: 45px;
	line-height: 45px;
	padding: 0 20px 0 20px;
	position: absolute;
	top: 20px;
	right: 0;
}
.col-content .title-bar .title
{
	font-size: 26px;
	font-weight: bold;
	float: right;
}
.col-content .title-bar .title2
{
	color: #8d95ac;
	font-size: 15px;
	font-weight: bold;
	float: right;
	margin-right: 5px;
}
*//*
.col-content .page-content-box
{
	background-color: #ffffff;
	width: calc(100% - 40px);
	height: calc(100% - 90px);
	position: absolute;
	top: 75px;
	left: 20px;
}
*//*
.col-content .page-content-box .page-content-top-bar
{
	width: 100%;
	height: 50px;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
}*/
/*
.col-content .page-content-box .page-content-top-bar .btn-add,
.col-content .page-content-box .page-content-top-bar .btn-upload,
.modal-upload-data .btn-add,
.modal-upload-data .btn-upload,
.modal-upload-data .btn-filter {
	height: 40px;
	font-size: 12px;
	float: right;
	margin-left: 10px;
	border: 1px solid #dfe0eb;
	border-radius: 8px;
	transition: 0.3s all ease;
}
.col-content .page-content-box .page-content-top-bar .btn-add:hover,
.col-content .page-content-box .page-content-top-bar .btn-upload:hover,
.modal-upload-data .btn-add:hover,
.modal-upload-data .btn-upload:hover {
	background-color: #dfe0eb;
}
.page-statistic .btn-filter {
	height: 40px;
	font-size: 12px;
	font-weight: bold;
	float: right;
	margin-left: 10px;
	border: 1px solid #dfe0eb;
	border-radius: 8px;
	transition: 0.3s all ease;
}
.page-statistic .btn-filter:hover {
	background-color: #dfe0eb;
	border: 1px solid #dfe0eb;
}
.col-content .page-content-box .page-content-top-bar .btn .icon,
.modal-upload-data .btn .icon {
	color: #66738f;
	font-size: 18px;
	margin-right: 5px;
}
*/
/*
.col-content .page-content-box .page-content-middle-bar
{
	width: 100%;
	height: calc(100% - 110px);
	padding: 0;
	position: absolute;
	top: 60px;
	left: 0;
	overflow: hidden;
}
.col-content .page-content-box .page-content-middle-bar.no-top-bar
{
	height: calc(100% - 50px);
	top: 0;
}
.col-content .page-content-box .page-content-middle-bar.no-bottom-bar
{
	height: calc(100% - 50px);
	top: 50px;
}
.col-content .page-content-box .page-content-middle-bar.no-top-bar-bottom-bar
{
	height: 100%;
	top: 0;
}

.col-content .page-content-box .page-content-full
{
	min-height: 100%;
	direction: ltr;

	min-width: 100%;
	position: absolute;
	top: 0;
}

.col-content .page-content-box .page-content-bottom-bar
{
	width: 100%;
	height: 50px;
	padding: 0;
	position: absolute;
	bottom: 0;
	left: 0;
}
.col-content .page-content-box .page-content-bottom-bar .page-content-bottom-bar-inner
{
	width: 100%;
	height: 50px;
	float: left;
	padding: 20px 0 0 0;
}

.col-content .page-content-box .page-content-bottom-bar .table-data
{
	line-height: 30px;
	color: #8f9caa;
	font-size: 14px;
	float: left;
}
*/

/**********************************************************************************************************************/
/*
.nav-tabs
{
	padding: 0;
	font-size: 10pt;
}
*/
/**********************************************************************************************************************/
/*
.table-show-data
{
	width: 100%;
	font-size: 14px;
	text-align: center;
	table-layout: fixed;
	position: relative;
}
.table-show-data thead tr
{
	background-color: #ebeef3;
	border-radius: 8px;
	position: sticky;
	top: 0;
}
.table-show-data thead tr th
{
	font-size: 13px;
	font-weight: bold;
	padding: 10px 15px;
}
.table-show-data thead tr th:last-child
{
	border-radius: 8px 0 0 8px;
}
.table-show-data thead tr th:first-child
{
	border-radius: 0 8px 8px 0;
}
.table-show-data tbody tr
{
	background-color: #fcfcfc;
	border-bottom: 1px solid #f8f8fa;
}
.table-show-data tbody tr:nth-child(odd)
{
	background-color: #ffffff;
}
.table-show-data tbody tr:hover
{
	background-color: #fafafa;
}
.table-show-data tbody tr td
{
	padding: 15px 15px;
}
.table-show-data tbody tr td .image
{
	width: 100%;
}
.table-show-data tbody tr td a .badge
{
	line-height: 1.5em;
	font-size: 10pt;
	font-weight: normal;
	white-space: normal;
}
.table-show-data tbody tr td .btn
{
	width: 100%;
	padding: 5px 0;
}
.table-show-data tbody tr td .btn.btn-half
{
	width: 50%;
}
.table-show-data tbody tr td .btn .btn-icon
{
	color: #888888;
	font-size: 16pt;
}



.table-show-data tbody tr td .link-badge
{
	line-height: 24px;
	font-size: 10pt;
	font-weight: normal;
	display: inline-block;
	padding-top: 0;
	padding-bottom: 0;
	margin: 2px 5px 2px 0;
}

*/
/**********************************************************************************************************************/
/*
.filter-box
{
	float: left;
	position: absolute;
	top: -60px;
	left: 0;
	z-index: 10;
}
.filter-box input, .filter-box select
{
	width: 250px;
	float: right;
	margin: 0 0 0 10px;
}
.filter-box select.no-value, .filter-box select option[value="0"]
{
	color: #b7c2ce;
}
.filter-box select option
{
	color: #191B1C;
}
.filter-box .filter-btn-submit
{
	height: 46px;
	color: #003bff !important;
	font-size: 18pt !important;
	padding: 0 5px !important;
}


.modal-backdrop
{
	width: calc(100% - 250px) !important;
	height: calc(100% - 20px) !important;
	top: 10px !important;
	right: auto !important;
	left: 10px !important;
	border-radius: 20px;
}
.modal-search
{
	width: calc(100% - 230px) !important;
	right: auto !important;
	left: 0 !important;
}

.btn-modal-search-show
{
	position: absolute;
	top: -55px;
	left: 0;
	z-index: 10;
}



.modal-search .modal-content
{
	border-radius: 16px;
}

.modal-search .modal-header .btn-close
{
	margin: 0;
}



.modal-search .modal-body .form-label
{
	color: #8f9caa;
}
.modal-search .modal-body input, .modal-search .modal-body select
{
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #d3dae2 !important;
	border-radius: 10px;
}
.modal-search .modal-body input:focus, .modal-search .modal-body select:focus
{
	border-color: #0d6efd !important;
}

.modal-search .modal-body .form-check-label
{
	margin: 3px 5px 0 0;
}
.modal-search .modal-body .form-check-inline
{

	padding-top: 7px;
}

.modal-search .modal-body .ddCoContainer .listContainer
{
	max-height: 350px;
}






.modal-search .modal-footer
{
	justify-content: flex-start;
}
.modal-search .modal-footer button
{
	margin: 0 10px 0 0;
}
.modal-search .modal-footer button .btn-icon
{
	color: #ffffff;
	font-size: 16pt;
}
*/

/**********************************************************************************************************************/
/*
.detail-master-box
{
	padding: 0 !important;
}

.detail-master-box .image-box
{
	text-align: center;
}
.detail-master-box .image-box .image
{
	width: 100%;
	max-width: 200px;
	text-align: center;
	margin-bottom: 30px;
}

.detail-master-box .label-value
{
	margin-bottom: 15px;
}
.detail-master-box .label-value:last-child
{
	margin-bottom: 0;
}
.detail-master-box .label
{
	width: 80px;
	line-height: 30px;
	color: #b5b5b5;
	font-weight: bold;
	font-size: 9pt;
	float: right;
}
.detail-master-box .value
{
	line-height: 30px;
	font-size: 11pt;
	padding-right: 80px;
}
.detail-master-box .value .badge
{
	line-height: 24px;
	font-size: 10pt;
	font-weight: normal;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 5px;
}

.chart-box
{
	width: 100%;
	max-width: 300px;
	margin: 30px auto 0 auto;
}
.chart-bar-box
{
	width: 100%;
	max-width: 1000px;
	margin: 30px auto 0 auto;
}
*/
/**********************************************************************************************************************/
/*
.page-login
{
	background-color: #031932;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.page-login .bg-page
{
	background-image: url("../images/bg-login.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 110%;
	height: 110%;
	position: absolute;
	top: -5%;
	left: -5%;
	filter: blur(5px);
	z-index: 1;
}

.form-login-box
{
	background: rgba(255, 255, 255, 0.2);
	width: 100%;
	max-width: 400px;
	padding: 40px;
	border-radius: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
}
.form-login-box .title
{
	line-height: 42px;
	color: #ffffff;
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;
}
.form-login-box .form-control
{
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #d3dae2;
	border-radius: 10px;
}
.form-login-box .btn
{
	background-color: #0976da;
	width: 100%;
	height: 40px;
	font-size: 16px;
	margin-top: 20px;
	border-radius: 10px;
}
.form-login-box .loader-wait
{
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	margin-top: 20px;
}
*/
/**********************************************************************************************************************/

.page-404
{
	width: 100%;
	height: 100%;
	position: relative;
}
.page-404 img
{
	width: 100%;
	max-width: 600px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/**********************************************************************************************************************/
/**********************************************************************************************************************/
/**********************************************************************************************************************/

.form-box .form-title
{
	font-size: 24px;
	font-weight: bold;
	margin: 0 0 24px 0;
}
/*
.dialog-content.form-box .form-container
{
	max-width: unset;
	padding: 0 !important;
}
.dialog-content.form-box .form-container .MuiGrid-container
{
	margin-top: 0;
}
.dialog-content.form-box .form-container .MuiGrid-item
{
	padding-top: 0;
}
*/
.form-box .form-container
{
	max-width: unset;
	padding: 0 !important;
}
.form-box .form-container .MuiGrid-container
{
	margin-top: 0;
}
.form-box .form-container .MuiGrid-item
{
	padding-top: 0;
}

.form-box .form-divider
{
	width: 100%;
	height: 1px;
}

.form-box .form-star
{
	position: relative;
}
.form-box .form-star:after
{
	color: #e74444;
	font-size: 18px;
	margin-left: 5px;
	content: '*';
}

.form-box .form-label
{
	color: #191b1c;
	font-size: 14px;
	text-align: left;
	margin-bottom: 8px;
	transform: none;
}
.form-box .form-label.form-label-m0
{
	margin-bottom: 0;
}
.form-box .form-input, .form-box .form-select, .form-box .form-input textarea
{
	width: 100%;
	margin-bottom: 16px;
}
.form-box.form-box-t2 .form-input, .form-box.form-box-t2 .form-select, .form-box.form-box-t2 .form-switch
{
	margin-bottom: 24px;
}
.form-box .form-input input, .form-box .form-select .MuiSelect-select, .form-box .form-input textarea
{
	background-color: #ffffff;
	width: 100%;
	height: 40px;
	font-size: 14px;
	/*padding: 7px 16px 0 16px;*/
	padding: 0 16px 0 16px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
	box-sizing: border-box;
}
.form-box .form-input textarea
{
	height: 80px !important;
	padding: 16px;
	overflow: auto !important;
}
.form-box .form-input .MuiInputBase-multiline
{
	padding: 0;
}
.form-box .form-select .MuiSelect-select
{
	padding-top: 7px;
}
.form-box .form-input input:focus, .form-box .form-select .MuiSelect-select:focus, .form-box .form-input textarea:focus
{
	border: 1px solid #005ce8 !important;
}
.form-box .form-input fieldset, .form-box .form-select fieldset
{
	border: 0;
}
.form-box .form-input.form-input-half
{
	width: 50%;
}


.form-box .form-input-search .MuiInputBase-formControl
{
	padding: 0;
	border-radius: 0;
}
.form-box .form-input-search input
{
	padding: 0 16px 0 16px !important;
}


.form-box.form-box-t2 .form-button-group
{
	margin-bottom: 24px;
	border-radius: 0;
	box-shadow: unset;
}
.form-box.form-box-t2 .form-button-group button
{
	background-color: #f5f6f7;
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	border: 0;
	border-bottom: 2px solid transparent;
	text-transform: unset;
}
.form-box.form-box-t2 .form-button-group button.Mui-selected
{
	background-color: #ffffff;
	color: #191b1c;
	border-bottom: 2px solid #005ce8;
}

.form-box.form-box-t2 .form-control-label-check-box
{
	margin-bottom: 24px;
}
.form-box.form-box-t2 .form-control-label-check-box .MuiFormControlLabel-label
{
	color: #191b1c;
	font-size: 14px;
	text-align: left;
	transform: none;
}

.form-box .MuiSwitch-root
{
	background-color: #005ce8;
	width: 32px;
	height: 20px;
	padding: 0;
	border-radius: 33px;
}
.form-box .MuiSwitch-root .MuiSwitch-track
{
	opacity: 0 !important;
}
.form-box .MuiSwitch-root .MuiSwitch-switchBase
{
	width: 12px;
	height: 12px;
	padding: 6px 0 0 0;
	left: 4px;
}
.form-box .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked
{
	color: #ffffff;
	-webkit-transform: translateX(12px);
	-moz-transform: translateX(12px);
	-ms-transform: translateX(12px);
	transform: translateX(12px);
}
/*.form-box .MuiSwitch-root .css-jsexje-MuiSwitch-thumb*/
.form-box .MuiSwitch-root .MuiSwitch-thumb
{
	width: 12px;
	height: 12px;
}

.form-box .react-datepicker-ignore-onclickoutside
{
	outline: unset !important;
}

.form-box .form-btn
{
	background-color: #005ce8;
	height: 40px;
	margin-top: 8px;
	text-transform: none;
	box-shadow: none;
	border-radius: 160px;
}

.form-box .form-content .MuiGrid-item
{
	padding-top: 0 !important;
	position: relative;
}
.form-box .form-content .MuiGrid-item:first-child
{
	padding-top: 16px !important;
}

.form-box .form-btn-password-show-hide
{
	color: #191b1c;
	cursor: pointer;
	position: absolute;
	top: 36px;
	right: 10px;
}
.form-box .form-btn-password-show-hide:hover
{
	color: #959fa3;
}

::-webkit-input-placeholder
{
	color: #959fa3 !important;
	opacity: 1 !important;
}
:-moz-placeholder
{
	color: #959fa3 !important;
	opacity: 1 !important;
}
::-moz-placeholder
{
	color: #959fa3 !important;
	opacity: 1 !important;
}
:-ms-input-placeholder
{
	color: #959fa3 !important;
	opacity: 1 !important;
}

/**********************************************************************************************************************/

.table-data
{
	background-color: #ffffff;
	width: 100%;
	border-spacing: 0;
	position: relative;
	z-index: 0;
}

.table-data thead
{
	background-color: #f8f9f9;
}
.table-data thead.sticky
{
	position: sticky;
	top: 0;
	z-index: 1;
}
.table-data thead th
{
	color: #4a5154;
	font-size: 12px;
	font-weight: normal;
	text-align: left;
	text-transform: uppercase;
	padding: 16px;
}
.table-data thead th.center
{
	text-align: center;
}
.table-data thead th.center .col-value
{
	justify-content: center;
}
.table-data thead .col-value
{
	width: 100%;
	display: flex;
	align-items: center;
}
.table-data thead .col-sort
{
	color: #626c70;
	transform: rotate(90deg);
}
.table-data thead .col-sort.asc
{
	color: #005ce8;
	transform: rotate(-90deg);
}
.table-data thead .col-sort.desc
{
	color: #005ce8;
	transform: rotate(90deg);
}

.table-data tbody
{

}
.table-data tbody td
{
	color: #4a5154;
	font-size: 14px;
	text-align: left;
	/*padding: 21px 16px;*/
	padding: 16px 16px;
}
.table-data tbody td.td-highlight
{
	color: #FF9500 !important;
}
.table-data tbody td.center
{
	text-align: center;
}
.table-data tbody td.color-gray-900
{
	color: #191b1c;
}
.table-data tbody tr:hover td
{
	background-color: #f0f6ff;
	color: #191b1c;
}

.table-data tbody td .icon-edit
{
	color: #005ce8;
}
.table-data tbody td .icon-delete
{
	color: #e84646;
}

.table-data tbody a, .table-data tbody a:visited
{
	color: #005ce8;
	font-size: 14px;
}
.table-data tbody a:hover
{
	color: #2386ff;
}

.table-data tfoot
{
	background-color: #ffffff;
	border-top: 1px solid #e5e7e8;
}
.table-data tfoot.sticky
{
	position: sticky;
	bottom: 0;
}
.table-data tfoot td
{
	padding: 24px;
	border-top: 1px solid #e5e7e8;
}

/**********************************************************************************************************************/

.label-status
{
	background-color: #ededed;
	color: #78848e;
	display: inline-block;
	padding: 5px 16px;
	border-radius: 130px;
}
.label-status.active
{
	background-color: #e7f7ef;
	color: #0faf62;
}
.label-status.pending
{
	background-color: #fffce0;
	color: #191b1c;
}
.label-status.pickup-or-delivery,
.label-status.complete
{
	background-color: #f0f6ff;
	color: #005ce8;
}
.label-status.jobs-done
{
	background-color: #fffce0;
	color: #191b1c;
}
.label-status.paused
{
	background-color: #f5f6f7;
	color: #191b1c;
}

.label-job
{
	width: 26px;
	height: 26px;
	display: inline-flex;
	margin-right: 2px;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
}
.label-job:last-child
{
	margin-right: 0;
}
.label-job.complete
{
	background-color: #e7f7ef;
	color: #0faf62;
}
.label-job.active
{
	background-color: #f0f6ff;
	color: #005ce8;
}
.label-job.overdue
{
	background-color: #fdeded;
	color: #e84646;
}
.label-job.paused
{
	background-color: #f5f6f7;
	color: #191b1c;
}

.label-task
{
	width: 26px;
	height: 26px;
	display: inline-flex;
	margin-right: 2px;
	align-items: center;
	justify-content: center;
}
.label-task:last-child
{
	margin-right: 0;
}
.label-task.mine
{
	background-color: #ff9500;
	color: #ffffff;
}
.label-task.open
{
	background-color: #fff4e6;
	color: #ff9500;
}

/**********************************************************************************************************************/

.w-30
{
	width: 30px;
}
.w-40
{
	width: 40px;
}
.w-80
{
	width: 80px;
}
.w-100
{
	width: 100px;
}
.w-120
{
	width: 120px;
}
.w-140
{
	width: 140px;
}
.w-160
{
	width: 160px;
}

/**********************************************************************************************************************/

.dialog-title
{
	padding: 20px 0;
	border-bottom: 2px solid #e5e7e8;
}
.dialog-title .title
{
	color: #005ce8;
	font-weight: 500;
	font-size: 18px;
}
.dialog-close
{
	background-color: #f5f6f7 !important;
	float: right;
}

.dialog-content
{
	padding-bottom: 0 !important;
}
.dialog-content .scrollbar
{
	width: 100% !important;
	height: 350px !important;
}

.dialog-actions
{
	padding: 0 24px 16px 24px !important;
	justify-content: flex-start !important;
}
.dialog-actions-2
{
	justify-content: space-between !important;
}
.dialog-btn-save
{
	background-color: #005ce8 !important;
	height: 40px;
	color: #ffffff !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	border: 2px solid #005ce8 !important;
	border-radius: 160px !important;
	box-shadow: unset;
	text-transform: unset !important;
}
.dialog-btn-save.dialog-btn-save-outline
{
	background-color: #ffffff !important;
	color: #005ce8 !important;
	border: 2px solid #005ce8 !important;
}
.dialog-btn-cancel
{
	background-color: #ffffff !important;
	height: 40px;
	color: #191b1c !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	border: 2px solid #191b1c !important;
	border-radius: 160px !important;
	box-shadow: unset;
	text-transform: unset !important;
}
.dialog-actions-2 .dialog-btn-cancel
{
	margin-left: 8px !important;
}

.dialog-btn-delete
{
	background-color: #ffffff !important;
	height: 40px;
	color: #E84646 !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	padding: 0 24px !important;
	border: 2px solid #E84646 !important;
	border-radius: 160px !important;
	box-shadow: unset;
	text-transform: unset !important;
}

/**********************************************************************************************************************/
