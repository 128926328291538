.page-settings .setting-section-container
{
	max-width: unset;
	padding: 0 !important;
}
.page-settings .setting-section-box
{
	background-color: #ffffff;
	padding: 20px;
	margin: 0 0 32px 0;
	border-radius: 8px;
}
.page-settings .setting-section-box .title
{
	color: #000000;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 16px;
}
.page-settings .setting-section-box .link, .page-settings .setting-section-box .link:visited
{
	width: 100%;
	color: #005ce8;
	font-weight: 600;
	font-size: 16px;
	display: inline-block;
	margin-bottom: 12px;
}
.page-settings .setting-section-box .link.disable
{
	color: #E5E7E8 !important;
	pointer-events: none;
}
.page-settings .setting-section-box .link:hover
{
	color: #2386ff;
}
.page-settings .setting-section-box .link:last-child
{
	margin-bottom: 0;
}

@media(max-width: 600px)
{
	.page-settings .setting-section-box:last-child
	{
		margin-bottom: 0;
	}
}
