.page-dashboard .dashboard-section-container
{
	max-width: unset;
	padding: 0 !important;
}




.page-dashboard .workflow-content
{
	width: 100%;
	/*display: inline-flex;*/
	/*align-items: center;*/
	/*justify-content: center;*/
}
.page-dashboard .workflow-station-box
{
	color: #191b1c;
	display: inline-flex;
	align-items: center;
	margin-bottom: 24px;
}
.page-dashboard .workflow-station-box .station-title-number-box
{
	width: 140px;
	text-align: center;
	cursor: pointer;
}
.page-dashboard .workflow-station-box .station-title-number-box .title-station-number
{
	padding: 8px;
	border: 2px solid #191b1c;
	border-radius: 8px;
	margin-bottom: 8px;
}
.page-dashboard .workflow-station-box .station-title-number-box .title-station-number .title
{
	font-weight: 600;
	font-size: 14px;
	padding: 0;
	margin: 0 0 16px 0;
	border: 0;
}
.page-dashboard .workflow-station-box .station-title-number-box .title-station-number .station-number
{
	background-color: #e5e7e8;
	width: max-content;
	font-weight: 600;
	font-size: 14px;
	padding: 8px 12px;
	margin: 0 auto;
	border-radius: 100px;
}
.page-dashboard .workflow-station-box .station-title-number-box .job-number
{
	font-weight: 600;
	font-size: 12px;
}
.page-dashboard .workflow-station-box .icon
{
	margin: 0 8px;
}
.page-dashboard .workflow-station-box:last-child .icon
{
	display: none;
}








.page-dashboard .dashboard-section-box
{
	background-color: #ffffff;
	padding: 20px;
	border-radius: 8px;
}
.page-dashboard .dashboard-section-box .title
{
	color: #191B1C;
	font-weight: 500;
	font-size: 18px;
	padding: 16px 0;
	margin-bottom: 32px;
	border-bottom: 1px solid #e5e7e8;
}

.page-dashboard .label-status-box
{
	font-weight: 600;
	font-size: 18px;
	display: inline-flex;
	align-items: center;
	margin: 0 32px 10px 0;
	cursor: pointer;
}
.page-dashboard .label-status-box .label-status
{
	width: 26px;
	height: 26px;
	font-weight: 600;
	font-size: 12px;
	display: inline-flex;
	padding: 0;
	margin-right: 10px;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
}
.page-dashboard .label-status-box .label-status.active
{
	background-color: #e7f7ef;
	color: #0faf62;
}
.page-dashboard .label-status-box .label-status.pending
{
	background-color: #fffce0;
	color: #191b1c;
}
.page-dashboard .label-status-box .label-status.overdue
{
	background-color: #e84646;
	color: #ffffff;
}
.page-dashboard .label-status-box .label-status.pickup-or-delivery
{
	background-color: #fff4e6;
	color: #ff9500;
}
.page-dashboard .label-status-box .label-status.completed
{
	background-color: #005ce8;
	color: #f0f6ff;
}
.page-dashboard .label-status-box .label-status.assgined
{
	background-color: #f0f6ff;
	color: #005ce8;
}

.page-dashboard .link-box
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-top: 32px;
}
.page-dashboard .link-to-view-all, .page-dashboard .link-to-view-all:visited
{
	background-color: #ffffff;
	height: 40px;
	color: #005ce8;
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	padding: 0 24px;
	border: 1px solid #005ce8;
	border-radius: 160px;
	align-items: center;
}
.page-dashboard .link-to-view-all:hover
{
	color: #2386ff;
	border: 1px solid #2386ff;
}

.page-dashboard .btn-new
{
	background-color: #005ce8;
	height: 40px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	padding: 0 24px;
	border-radius: 160px;
	align-items: center;
	box-shadow: unset;
	text-transform: uppercase;
}
.page-dashboard .btn-new .icon
{
	font-size: 18px;
	margin-right: 4px;
}

.page-dashboard .btn-edit
{
	background-color: #f0f6ff;
	height: 40px;
	color: #005ce8;
	font-size: 14px;
	font-weight: 600;
	display: inline-flex;
	padding: 0 24px;
	margin-left: 16px;
	border-radius: 160px;
	align-items: center;
	box-shadow: unset;
}
.page-dashboard .btn-edit:hover
{
	background-color: #e5ebf4;
	box-shadow: unset;
}
