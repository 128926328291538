.dialog-content-category .scrollbar
{
	margin: 24px 0;
}

.dialog-content-category .table-data tbody td
{
	padding: 3px 5px;
}

.dialog-content-category .table-data tbody td .icon-edit
{
	color: #005ce8;
}
.dialog-content-category .table-data tbody td .icon-delete
{
	color: #e84646;
}
