.dialog-content-preset-task .scrollbar
{
	margin: 24px 0;
}

.dialog-content-preset-task .table-data thead th
{
	padding-top: 8px;
	padding-bottom: 8px;
}
.dialog-content-preset-task .table-data tbody td
{
	padding-top: 0;
	padding-bottom: 0;
}

.dialog-content-preset-task .table-data tbody td .MuiCheckbox-root
{
	padding-top: 0;
	padding-bottom: 0;
}

.dialog-content-preset-task .table-data tbody td .icon-edit
{
	color: #005ce8;
}
.dialog-content-preset-task .table-data tbody td .icon-delete
{
	color: #e84646;
}

.dialog-content-preset-task .scrollbar-preset-task-pre,
.dialog-content-preset-task .scrollbar-preset-task-post
{
	height: 180px !important;
}

.dialog-content-preset-task .MuiAlert-root
{
	margin: 24px 0;
}

.dialog-content-preset-task .section-title
{
	font-weight: 600;
	font-size: 16px;
	color: #959fa3;
	margin-right: 24px;
	text-transform: uppercase;
}
.dialog-content-preset-task .section-des
{
	font-size: 16px;
	color: #191b1c;
	margin: 24px 0;
}
