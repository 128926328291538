.page-search .tab-bar
{
	margin-bottom: 24px;
}
.page-search .tab-bar .MuiTabs-root
{
	min-height: unset;
}
.page-search .tab-bar .tab
{
	max-width: unset;
	min-width: unset;
	min-height: unset;
	color: #626c70;
	font-size: 14px;
	font-weight: 500;
	padding: 12px 16px;
	letter-spacing: unset;
	text-transform: unset;
}
.page-search .tab-bar .tab.Mui-selected
{
	background-color: #ffffff;
	color: #191b1c;
}

.search-result
{
}
.search-result .search-result-title
{
	margin-bottom: 24px;
}
.search-result .search-result-title .title
{
	background-color: #f0f6ff;
	color: #005ce8;
	display: inline-block;
	padding: 5px 16px;
	border-radius: 130px;
}
.search-result .search-result-title.job .title
{
	background-color: #fffce0;
	color: #191b1c;
}
.search-result .search-result-title.customer .title
{
	background-color: #e7f7ef;
	color: #0faf62;
}
.search-result .search-result-title.vendor .title
{
	background-color: #fff4e6;
	color: #ff9500;
}

.search-result .search-item
{
	display: flex;
	margin-bottom: 24px;
}
.search-result .search-item .col
{
	max-width: 250px;
	display: inline-block;
	padding: 16px 100px 0 0;
	border-top: 1px solid #959fa3;
}
.search-result .search-item .col:last-child
{
	padding-right: 0;
}
.search-result .search-item .col .title
{
	color: #7b878c;
	text-align: left;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 8px;
	white-space: nowrap;
}
.search-result .search-item .col .value
{
	line-height: 28px;
	color: #191b1c;
	text-align: left;
	font-size: 20px;
}
.search-result .search-item .col .value a, .search-result .search-item .col .value a:visited
{
	color: #005ce8;
}
.search-result .search-item .col .value a:hover
{
	color: #167fff;
}
