.page-job .job-section-container
{
	max-width: unset;
	padding: 0 !important;
}

.page-job .title-bar-breadcrumbs-status
{
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
}
.page-job .title-bar-breadcrumbs-status .title-bar-breadcrumbs-status-left
{
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
}
.page-job .title-bar-breadcrumbs-status .label-status
{
	margin-left: 64px;
}
.page-job .title-bar-breadcrumbs-status .breadcrumbs
{
	margin-bottom: 0;
}

.page-job .title-bar-breadcrumbs-status .label-status.paused
{
	background-color: #b0b7ba;
	color: #191b1c;
}


.page-job .title-bar-breadcrumbs-status .workflow-station-box
{
	margin-left: 64px;
	margin-bottom: 0;
}
.page-job .title-bar-breadcrumbs-status .workflow-station-box .station-title-number-box
{
	width: unset;
}
.page-job .title-bar-breadcrumbs-status .workflow-station-box .station-title-number-box .title-station-number
{
	background-color: #ffffff;
	margin-bottom: 0;
}




.page-job .title-bar-breadcrumbs-status .btn-job-order
{
	background-color: transparent !important;
	padding: 8px !important;
	border-radius: 8px !important;
}
.page-job .title-bar-breadcrumbs-status .btn-job-order .job-number
{
	background-color: #005ce8;
	width: 26px;
	height: 26px;
	color: #ffffff;
	font-weight: 600;
	font-size: 12px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	border-radius: 100%;
}
.jobs-order-menu .MuiPaper-root
{
	background-color: #f0f6ff;
	padding: 12px;
	box-shadow: unset;
	border: 1px solid #e5e7e8;
	border-radius: 8px;
	display: inline-flex;
	flex-direction: column;
}
.jobs-order-menu .col
{
	margin-right: 24px;
}
.jobs-order-menu .title
{
	color: #000000;
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 24px;
}

.jobs-order-menu .title .link-order-view
{
	color: #005ce8;
	font-size: 14px;
	display: inline-flex;
	align-items: center;
	padding: 3px 7px;
	border: 1px solid #005ce8;
	border-radius: 135px;
}
.jobs-order-menu .title .link-order-view .icon
{
	font-size: 16px;
	margin-right: 5px;
}




.jobs-order-menu .jobs-order-menu-item
{
	background-color: #ffffff;
	width: 100%;
	display: inline-flex;
	align-items: center;
	padding: 16px;
	box-sizing: border-box;
	justify-content: space-between;
	cursor: pointer;
}
.jobs-order-menu .jobs-order-menu-item.active
{
	background-color: #f0f6ff;
}
.jobs-order-menu .jobs-order-menu-item:hover
{
	background-color: #f0f6ff;
}
.jobs-order-menu .workflow-station-box
{
	color: #191b1c;
	display: inline-flex;
	align-items: center;
	text-align: center;
}
.jobs-order-menu .workflow-station-box .station-title-number-box .title-station-number
{
	background-color: #ffffff;
	padding: 8px;
	border: 2px solid #191b1c;
	border-radius: 8px;
}
.jobs-order-menu .workflow-station-box .station-title-number-box .title-station-number .title
{
	font-weight: 600;
	font-size: 14px;
	padding: 0;
	margin: 0 0 16px 0;
	border: 0;
}
.jobs-order-menu .workflow-station-box .station-title-number-box .title-station-number .station-number
{
	background-color: #e5e7e8;
	width: max-content;
	font-weight: 600;
	font-size: 14px;
	padding: 8px 12px;
	margin: 0 auto;
	border-radius: 100px;
}

















.page-job .section-divider
{
	margin-bottom: 8px;
}
.page-job .section-divider .section-title
{
	width: 100%;
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	margin-right: 24px;
	text-transform: uppercase;
}




.page-job .job-section-box
{
	background-color: #ffffff;
	width: 100%;
	/*height: 100%;*/
	padding: 20px;
	border-radius: 8px;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
}
.page-job .job-section-box .title-bar
{
	color: #000000;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 32px;
}
.page-job .job-section-box .title
{
	color: #626c70;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
}


.page-job .job-section-action-box
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}
.page-job .job-section-action-box .next-station-box
{
	display: inline-flex;
	align-items: center;
}
.page-job .job-section-action-box .next-station-button
{
	padding: 0 !important;
	margin: 0 8px 0 0 !important;
}

.page-job .job-section-action-box .Mui-disabled
{
	color: rgba(255, 255, 255, 0.5);
}


.btn-group-green-2 .btn, .btn-group-blue .btn
{
	background-color: transparent !important;
	padding: 0 !important;
	border: 0 !important;
}
.btn-group-green-2 .btn:first-child, .btn-group-blue .btn:first-child
{
	padding: 0 10px 0 15px !important;
}








.next-station-menu .next-station-menu-item
{
	color: #626c70;
}
.next-station-menu .next-station-menu-item.item-delete
{
	color: #e84646;
}
.next-station-menu .next-station-menu-item .icon
{
	width: 20px;
	margin-right: 8px;
}


.page-job .form-job-assigned-user-label
{
	top: -5px;
}
.page-job .form-job-assigned-user
{
	margin-bottom: 0;
}
.page-job .form-job-assigned-user .MuiSelect-select
{
	width: 200px;
	border: 0 !important;
	padding-top: 10px;
}
.page-job .form-job-assigned-user .MuiSelect-select:focus
{
	border: 0 !important;
}




.page-job .job-section-work-flow
{
	margin-bottom: 24px;
}
.page-job .job-section-work-flow .job-section-box
{
	padding-bottom: 0;
}
.page-job .workflow-content
{
	width: 100%;
}
.page-job .workflow-station-box
{
	color: #191b1c;
	display: inline-flex;
	align-items: center;
	margin-bottom: 24px;
}
.page-job .workflow-station-box .station-title-number-box
{
	width: 140px;
	text-align: center;
}
.page-job .workflow-station-box .station-title-number-box .title-station-number
{
	padding: 8px;
	border: 2px solid #191b1c;
	border-radius: 8px;
	margin-bottom: 8px;
}
.page-job .workflow-station-box.active .station-title-number-box .title-station-number
{
	border: 2px solid #005ce8;
}
.page-job .workflow-station-box .station-title-number-box .title-station-number .title
{
	font-weight: 600;
	font-size: 14px;
	padding: 0;
	margin: 0 0 16px 0;
	border: 0;
}
.page-job .workflow-station-box .station-title-number-box .title-station-number .station-number
{
	background-color: #e5e7e8;
	width: max-content;
	font-weight: 600;
	font-size: 14px;
	padding: 8px 12px;
	margin: 0 auto;
	border-radius: 100px;
}
.page-job .workflow-station-box.active .station-title-number-box .title-station-number .station-number
{
	background-color: #f0f6ff;
}
.page-job .workflow-station-box .station-title-number-box .job-number
{
	font-weight: 600;
	font-size: 12px;
}
.page-job .workflow-station-box .station-title-number-box .job-current
{
	color: #005ce8;
	font-weight: 600;
	font-size: 12px;
}
.page-job .workflow-station-box .icon
{
	margin: 0 8px;
}
.page-job .workflow-station-box:last-child .icon
{
	display: none;
}









.page-job .order-job-box
{
	padding: 24px;
	border-radius: 8px;
	margin-bottom: 24px;
}

.page-job .order-job-box .MuiGrid-container
{
	margin-top: 0;
}
.page-job .order-job-box .MuiGrid-item
{
	padding-top: 0;
}
.page-job .order-job-box .container-date
{
	width: 100%;
	max-width: 400px;
}
.page-job .order-job-box .data-box
{
	margin-bottom: 24px;
}
.page-job .order-job-box .data-box .title
{
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 8px;
	text-transform: uppercase;
}
.page-job .order-job-box .data-box .des
{
	color: #191b1c;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 8px;
}
.page-job .order-job-box .data-box .des:last-child
{
	margin-bottom: 0;
}


.page-job .order-job-box .data-box .des-link
{
	margin-bottom: 8px;
}
.page-job .order-job-box .data-box .des-link:last-child
{
	margin-bottom: 0;
}
.page-job .order-job-box .data-box .des-link a, .page-job .order-job-box .data-box .des-link a:visited
{
	color: #005ce8;
	font-size: 14px;
	/*margin-bottom: 8px;*/
}
.page-job .order-job-box .data-box .des-link a:hover
{
	color: #2386ff;
}




.page-job .order-job-box .date-blue
{
	background-color: #f0f6ff;
	color: #005ce8;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}
.page-job .order-job-box .date-green
{
	background-color: #e7f7ef;
	color: #0faf62;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}
.page-job .order-job-box .date-orange
{
	background-color: #FFF4E6;
	color: #FF9500;
	font-weight: 500;
	font-size: 14px;
	padding: 5px 16px;
	border-radius: 130px;
}



.page-job .order-job-box .data-box-border
{
	height: 100%;
	padding: 20px;
	margin-bottom: 0;
	border: 1px solid #cacfd1;
	border-radius: 8px;
	box-sizing: border-box;
}
.page-job .order-job-box .data-box-border .title
{
	color: #959fa3;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 4px;
}
.page-job .order-job-box .data-box-border .des
{
	color: #000000;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 16px;
}
.page-job .order-job-box .data-box-border.note-data-box
{
	width: 100%;
	display: inline-flex;
	flex-direction: column;
}
.page-job .order-job-box .data-box-border .note-box
{
	flex-basis: 100%;
	min-height: 500px;
	font-size: 14px;
	padding: 10px 16px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
}


.page-job .order-job-box .data-box-border.production-note-data-box
{
	width: 100%;
	display: inline-flex;
	flex-direction: column;
}
.page-job .order-job-box .data-box-border.production-note-data-box .form-input
{
	flex-basis: 100%;
	margin-bottom: 16px;
}
.page-job .order-job-box .data-box-border.production-note-data-box .form-input .MuiInputBase-multiline
{
	height: 100% !important;
	padding: 0;
}
.page-job .order-job-box .data-box-border.production-note-data-box .form-input textarea
{
	width: 100% !important;
	height: 100% !important;
	color: #959fa3;
	font-size: 14px;
	padding: 10px 16px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
	box-sizing: border-box;
}
.page-job .order-job-box .data-box-border.production-note-data-box .form-input textarea:focus
{
	border: 1px solid #005ce8 !important;
}
.page-job .order-job-box .data-box-border.production-note-data-box .form-input fieldset
{
	border: 0;
}

.page-job .order-job-box .btn-job-order-edit
{
	margin-top: 32px;
}




















.page-job .job-section-job-chat-box
{
	justify-content: space-between !important;
}
.page-job .job-section-job-chat-box .title-bar
{
	margin-bottom: 0 !important;
}
.page-job .job-section-job-chat-box .job-chat-item
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;
}
.page-job .job-section-job-chat-box .job-chat-item .col-left
{
	flex: 1;
	padding-right: 8px;
}
.page-job .job-section-job-chat-box .job-chat-item .col-right
{
	width: 120px;
	color: #6e9fe8;
	font-size: 16px;
	text-align: right;
}
.page-job .job-section-job-chat-box .job-chat-item .user
{
	color: #191b1c;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 4px;
}
.page-job .job-section-job-chat-box .job-chat-item .message
{
	color: #7b878c;
	font-size: 16px;
}
.page-job .job-section-job-chat-box .job-chat-item .btn-delete
{
	color: #e84646;
	margin-left: 4px;
}


.page-job .job-section-job-history-box
{
	justify-content: space-between !important;
}
.page-job .job-section-job-history-box .title-bar
{
	margin-bottom: 0 !important;
}
.page-job .job-section-job-history-box .job-history-item
{
	width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;
}
.page-job .job-section-job-history-box .job-history-item .col-left
{
	flex: 1;
	padding-right: 8px;
}
.page-job .job-section-job-history-box .job-history-item .col-right
{
	width: 90px;
	text-align: right;
}
.page-job .job-section-job-history-box .job-history-item .user
{
	color: #191b1c;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 4px;
}
.page-job .job-section-job-history-box .job-history-item .title
{
	color: #005ce8;
	font-size: 12px;
}
.page-job .job-section-job-history-box .job-history-item .message
{
	color: #7b878c;
	font-size: 16px;
}
.page-job .job-section-job-history-box .job-history-item .date
{
	color: #6e9fe8;
	font-size: 16px;
}
.page-job .job-section-job-history-box .job-history-item .time
{
	color: #7b878c;
	font-size: 12px;
}

.page-job .job-section-job-history-box .btn-box, .page-job .job-section-job-chat-box .btn-box
{
	margin-top: 16px;
}



.bar-icon-show-hide
{
	position: relative;
}
.bar-icon-show-hide .btn-show-hide
{
	position: absolute;
	bottom: 0;
	right: 0;
}
