.page-jobs .search-box
{
	margin-left: 24px;
}
.page-jobs .search-box .form-input input:focus
{
	border: 0 !important;
}

.page-jobs .filter-bar .filter-select-box
{
	margin-right: 24px;
	margin-bottom: 4px;
}
.page-jobs .filter-bar .filter-select-box:last-child
{
	margin-right: 0;
}
