.dialog-content-custom-characteristic .scrollbar
{
	margin: 24px 0;
}

.dialog-content-custom-characteristic .table-data thead th
{
	padding-top: 8px;
	padding-bottom: 8px;
}
.dialog-content-custom-characteristic .table-data tbody td
{
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.dialog-content-custom-characteristic .table-data tbody td .MuiCheckbox-root
{
	padding-top: 0;
	padding-bottom: 0;
}

.dialog-content-custom-characteristic .table-data tbody td .icon-edit
{
	color: #005ce8;
}
.dialog-content-custom-characteristic .table-data tbody td .icon-delete
{
	color: #e84646;
}

.dialog-content-custom-characteristic .scrollbar-custom-characteristic
{
	height: 200px !important;
}

.table-data-job-option
{
	margin: 24px 0;
}

.dialog-content-custom-characteristic .dialog-actions
{
	padding: 0 0 16px 0 !important;
}
.dialog-content-custom-characteristic .dialog-actions .dialog-btn-save
{
	margin-right: 24px !important;
}

.dialog-content-custom-characteristic .MuiAlert-root
{
	margin: 24px 0;
}

.form-box.form-box-t2 .form-button-group
{
	margin-bottom: 24px;
	border-radius: 0;
	box-shadow: unset;
}
.form-box.form-box-t2 .form-button-group button
{
	background-color: #ffffff;
	color: #626c70;
	font-weight: 500;
	font-size: 14px;
	padding: 12px 16px;
	border: 0;
	border-bottom: 2px solid transparent;
	border-radius: 0;
	text-transform: unset;
}
.form-box.form-box-t2 .form-button-group button.Mui-selected
{
	font-weight: bold;
	color: #191b1c;
	border-bottom: 2px solid #005ce8;
}

.form-custom-characteristic-btn-add-to-list
{
	color: #0faf62 !important;
	position: absolute !important;
	top: 28px;
	right: 0;
}

.form-custom-characteristic-list-name-box
{
	position: relative;
}
.characteristic-list-box
{
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 24px;
}
.characteristic-list-box .characteristic-list-row
{
	width: 100%;
	padding: 0 12px;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
}
