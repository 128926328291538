.page-reports .title-bar .MuiTabs-root
{
	min-height: unset;
	margin-left: 16px;
}
.page-reports .title-bar .tab
{
	max-width: unset;
	min-width: unset;
	min-height: unset;
	color: #626c70;
	font-size: 14px;
	font-weight: 500;
	padding: 12px 16px;
	letter-spacing: unset;
	text-transform: unset;
}
.page-reports .title-bar .tab.Mui-selected
{
	background-color: #ffffff;
	color: #191b1c;
}

.page-reports .search-box
{
}
.page-reports .search-box .form-input input:focus
{
	border: 0 !important;
}

.page-reports .filter-bar
{
	display: inline-flex;
	justify-content: space-between;
}
.page-reports .filter-bar .filter-select-box
{
	margin-right: 24px;
}

.form-order-stock-call-customer-check-box .MuiCheckbox-root
{
	color: #e5e7e8;
	padding-top: 0;
	padding-bottom: 0;
}

.page-reports .table-data .link, .page-reports .table-data .link:visited
{
	width: unset;
	min-width: unset;
	font-size: 14px !important;
	font-weight: normal;
	text-transform: unset;
	padding: 0 !important;
}
.page-reports .table-data .link:hover
{
	background-color: transparent !important;
	color: #2386ff;
}

.page-reports .table-data .input-date-box input
{
	background-color: #ffffff;
	width: 100%;
	font-size: 14px;
	padding: 8px;
	border: 1px solid #e5e7e8;
	border-radius: 4px;
	box-sizing: border-box;
}
.page-reports .table-data .input-date-box input:focus
{
	border: 1px solid #005ce8 !important;
}
.page-reports .table-data .input-date-box .react-datepicker-ignore-onclickoutside
{
	outline: unset !important;
}
