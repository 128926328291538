.form-customer-box .form-control-label-check-box
{
	margin: 0 0 0 8px !important;
}
.form-customer-box .form-control-label-check-box .MuiCheckbox-root
{
	color: #e5e7e8;
	padding: 0;
}
.form-customer-box .form-control-label-check-box .Mui-checked
{
	color: #1976d2!important;
}

.form-customer-box .form-control-label-check-box svg
{
	width: 18px;
	height: 18px;
}

.page-customers .filter-bar .search-box .form-input input:focus
{
	border: 0 !important;
}
