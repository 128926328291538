.dialog-content-station .scrollbar
{
	margin: 24px 0;
}

.dialog-content-station .table-data tbody td
{
}

.dialog-content-station .table-data tbody td .icon-edit
{
	color: #005ce8;
}
.dialog-content-station .table-data tbody td .icon-delete
{
	color: #e84646;
}
