.page-profile .profile-section-container
{
	max-width: unset;
	padding: 0 !important;
}





.page-profile .profile-section-box
{
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	padding: 20px;
	border-radius: 8px;
	box-sizing: border-box;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
}
.page-profile .profile-section-box .title
{
	color: #626c70;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
}



.page-profile .profile-section-user-plan
{
	margin-bottom: 24px;
}


.page-profile .profile-section-box .user-plan-status
{
	margin-top: 12px;
}


.page-profile .profile-section-box .user-plan-level-type-link
{
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
}


.page-profile .profile-section-box .user-plan-level-type-link .user-plan-level-type
{
}
.page-profile .profile-section-box .user-plan-level-type-link .user-plan-level-type .user-plan-level
{
	line-height: 32px;
	color: #191b1c;
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 4px;
}
.page-profile .profile-section-box .user-plan-level-type-link .user-plan-level-type .user-plan-type
{
	line-height: 24px;
	color: #191b1c;
	font-size: 24px;
}


.page-profile .profile-section-box .user-plan-level-type-link .user-plan-link
{
}
.page-profile .profile-section-box .user-plan-level-type-link .user-plan-link .link, .page-profile .profile-section-box .user-plan-level-type-link .user-plan-link .link:visited
{
	color: #005ce8;
	font-size: 14px;
}
.page-profile .profile-section-box .user-plan-level-type-link .user-plan-link .link:hover
{
	color: #2386ff;
}






.page-profile .profile-section-box .user-plan-des
{
	color: #7b878c;
	font-weight: 500;
	font-size: 14px;
	text-align: justify;
	margin-top: 4px;
}



.page-profile .profile-section-box .upgrade-title
{
	color: #191b1c;
	font-weight: 600;
	font-size: 36px;
	margin-top: 32px;
}
.page-profile .profile-section-box .upgrade-des
{
	color: #959fa3;
	font-size: 16px;
	margin-top: 16px;
	margin-bottom: 32px;
}





.page-profile .profile-section-box .visa-card-data
{
	margin-top: 12px;
	display: inline-flex;
	justify-content: space-between;
	margin-bottom: 24px;
}
.page-profile .profile-section-box .visa-card-data .card-number
{
	color: #191b1c;
	font-size: 20px;
}
.page-profile .profile-section-box .visa-card-data .card-number img
{
	width: 24px;
	margin-right: 5px;
}
.page-profile .profile-section-box .visa-card-data .expire
{
	color: #191b1c;
	font-size: 20px;
}













.page-profile .scrollbar-box
{
	height: 100px;
	max-height: 500px;
}
.page-profile .scrollbar-invoice-box
{
	margin-bottom: 24px;
}

.page-profile .section-divider
{
	margin-bottom: 8px;
}
.page-profile .section-title
{
	font-weight: 600;
	font-size: 16px;
	color: #191b1c;
	margin-right: 24px;
}
